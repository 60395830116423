import React, {useState, useEffect, useRef} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import ProductSelect from "../product/ProductSelect";
import {
    downloadDeliveryStatement, downloadDeliveryStatementByDate,
    getProductById,
    getRentalById,
} from "../../utils/requestUtils";
import Config from "../../core/config";
import {
    adjustDateToBrowserTimeZone,
    calculateDaysDifference,
    formatDateToAmericanFormat,
    formatDateToString,
    getOnlyDate
} from "../../utils/utils";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import {saveAs} from 'file-saver';
import SearchButton from "../../core/SearchButton";
import DatePicker from "react-datepicker";
import Cookies from "js-cookie";
import WhatsAppButton from "../../core/WhatsAppButton";
import GoogleMapsButton from "../../core/GoogleMapsButton";
import TimePeriodPicker from "../customer/TimePeriodPicker";
import timeImg from "../../img/icons8-time-10.png"
import searchImg from "../../img/icons8-whatsapp-32.png";
import {formatTime} from "../../core/date";
import {useReactToPrint} from "react-to-print";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import ViewCustomerButton from "../../core/ViewCustomerButton";
import CustomerViewer from "../customer/CustomerViewer";
import ModalDiv from "../../ModalDiv";

const apiUrl = Config.apiUrl;
const ProductRentedListToDeliverReturn = (props) => {
    const {delivered, onRequestExtendRent} = props;
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState(undefined);
    const [showProductSelect, setShowProductSelect] = useState(false);
    const [showCustomerViewer, setShowCustomerViewer] = useState(false);
    const [customerViewerId, setCustomerViewerId] = useState(undefined);
    const [showTimePeriodPicker, setShowTimePeriodPicker] = useState(false);
    const inputQueryString = useRef(null);
    const [, setLoading] = useRecoilState(loading);
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [deliveryStatus, setDeliveryStatus] = useState(0);
    const [indexItemChangingTime, setIndexItemChangingTime] = useState(-1);
    const [startTimePeriodChanging, setStartTimePeriodChanging] = useState(undefined);
    const [endTimePeriodChanging, setEndTimePeriodChanging] = useState(undefined);

    async function makeQuery(startDate_, endDate_, deliveryStatus) {
        try {
            setLoading(true);
            if (delivered && deliveryStatus === 2 && (!startDate_ || !endDate_)) {
                alert('Para consultar itens devolvidos, informe o período.');
                return;
            }

            const newStartDate = startDate_ ? startDate_ : new Date(1900, 0, 1);
            const newEndDate = endDate_ ? endDate_ : new Date(2100, 0, 1);
            const deliveryStatusList = deliveryStatus === 1 ? [0, 1] : [deliveryStatus];
            const url = `${apiUrl}/api/productRented/findRentals?startDate=${formatDateToAmericanFormat(newStartDate)}&endDate=${formatDateToAmericanFormat(newEndDate)}&status=${-1}&productId=${product ? product.id : 0}&deliveryStatus=${deliveryStatusList.join(',')}&delivered=${delivered}`;
            const rentals = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
            // rentals.map((r) => {
            //     r.rentalItems.map((ri) => {
            //         products = [...products, {rental: r, rentalItem: ri}]
            //     })
            // });

            const products = rentals.reduce((acc, rental) => {
                rental.rentalItems.forEach((ri) => {
                    const date = delivered ? ri.returnDate : ri.rentalDate;
                    const key = `${rental.id}-${date}`;

                    const startTime = delivered ? ri.returnStartTime : ri.deliveryStartTime;
                    const endTime = delivered ? ri.returnEndTime : ri.deliveryEndTime;

                    if (!acc[key]) {
                        acc[key] = {
                            rental: rental,
                            rentalItems: [],
                            date: date,
                            startTime: startTime,
                            endTime: endTime,
                        };
                    }

                    acc[key].rentalItems.push(ri);
                });

                return acc;
            }, {});

            const sortedProducts = Object.values(products).sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);

                if (dateA - dateB !== 0) {
                    return dateA - dateB;
                } else if (a.startTime !== b.startTime) {
                    return a.startTime < b.startTime ? -1 : 1;
                } else {
                    return a.endTime < b.endTime ? -1 : 1;
                }
            });
            setProducts(sortedProducts);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (inputQueryString.current) {
            inputQueryString.current.focus();
        }
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        setEndDate(tomorrow);
        setStartDate(undefined);
        const deliveryStatus = delivered ? 1 : 0;
        setDeliveryStatus(deliveryStatus);
        makeQuery(undefined, tomorrow, deliveryStatus);
    }, []);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };
    //
    // const handleInputChange = (e) => {
    //     const string = e.target.value;
    //     if (string.length >= 3 || string.length === 0) {
    //         makeQuery(string);
    //     }
    //     setQueryString(string);
    // };

    async function onFinishClick(id, date) {
        setLoading(true);
        const rental = await getRentalById(id);
        if (delivered) {
            const finish = deliveryStatus === 1;
            if (!window.confirm(finish ? 'Deseja realmente baixar?' : 'Deseja realmente estornar a devolucão?')) {
                return;
            }
            for (let i = 0; i < rental.rentalItems.length; i++) {
                const rentalItem = rental.rentalItems[i];

                const url = apiUrl + '/api/rentalItem/' + (finish ? 'finishRentalItems' : 'reverseReturnedRentalItems') + '?id=' + rentalItem.id + '&date=' + formatDateToAmericanFormat(getOnlyDate(adjustDateToBrowserTimeZone(new Date(date))));
                await makeRequest(url, 'PUT', {
                    'Content-Type': 'application/json',
                    'Useruuid': Cookies.get('userUUID')
                });
            }
        } else {
            const deliver = deliveryStatus === 0;
            if (!window.confirm(deliver ? 'Deseja realmente entregar?' : 'Deseja realmente estornar a entrega?')) {
                return;
            }
            for (let i = 0; i < rental.rentalItems.length; i++) {
                const rentalItem = rental.rentalItems[i];

                const url = apiUrl + '/api/rentalItem/' + (deliver ? 'deliverRentalItems' : 'reverseDeliveredRentalItems') + '?id=' + rentalItem.id + '&date=' + formatDateToAmericanFormat(getOnlyDate(adjustDateToBrowserTimeZone(new Date(date))));
                await makeRequest(url, 'PUT', {
                    'Content-Type': 'application/json',
                    'Useruuid': Cookies.get('userUUID')
                });
            }
        }
        setLoading(false);

        await makeQuery(startDate, endDate, deliveryStatus);
    }


    async function selectProduct(id) {
        const product = await getProductById(id);
        setProduct(product);
    }

    const handleProductItemClick = async (id) => {
        await selectProduct(id);
        closeProductSelect();

    };

    const openProductSelect = () => {
        setShowProductSelect(true);
    };


    const closeProductSelect = () => {
        setShowProductSelect(false);
    };

    const changeTimePeriod = async (startTime, endTime) => {
        const rentalTemp = await getRentalById(products[indexItemChangingTime].rental.id);
        if (delivered) {
            for (let i = 0; i < rentalTemp.rentalItems.length; i++) {
                const rentalItem = rentalTemp.rentalItems[i];
                const url = apiUrl + '/api/rentalItem/return-period?rentalId=' + rentalTemp.id + '&date=' + formatDateToAmericanFormat(getOnlyDate(adjustDateToBrowserTimeZone(new Date(rentalItem.returnDate)))) + '&returnStartTime=' + startTime + ':00' + '&returnEndTime=' + endTime + ':00';
                await makeRequest(url, 'PUT', {
                    'Content-Type': 'application/json',
                    'Useruuid': Cookies.get('userUUID')
                });
            }
        } else {

            for (let i = 0; i < rentalTemp.rentalItems.length; i++) {
                const rentalItem = rentalTemp.rentalItems[i];

                const url = apiUrl + '/api/rentalItem/delivery-period?rentalId=' + rentalTemp.id + '&date=' + formatDateToAmericanFormat(getOnlyDate(adjustDateToBrowserTimeZone(new Date(rentalItem.rentalDate)))) + '&deliveryStartTime=' + startTime + ':00' + '&deliveryEndTime=' + endTime + ':00';
                await makeRequest(url, 'PUT', {
                    'Content-Type': 'application/json',
                    'Useruuid': Cookies.get('userUUID')
                });
            }
        }

        await makeQuery(startDate, endDate, deliveryStatus);
        setShowTimePeriodPicker(false);
    };

    function handleOnClickProduct() {
        openProductSelect();
    }

    const componentRef = useRef();

    function getBackgroundColor(item) {
        const currentDate = getOnlyDate(new Date());
        const rentalDate = getOnlyDate(adjustDateToBrowserTimeZone(new Date(item.date)));
        const returnDate = getOnlyDate(adjustDateToBrowserTimeZone(new Date(item.date)));
        const errorColor = 'var(--error-color)';
        const warningColor = 'var(--warning-color)';
        const normalColor = 'var(--normal-color)';
        if (delivered) {
            if (returnDate < currentDate)
                return errorColor;
            if (calculateDaysDifference(returnDate, currentDate) === 0) {
                return warningColor;
            }
        } else {
            if (rentalDate < currentDate)
                return errorColor;
            if (calculateDaysDifference(rentalDate, currentDate) === 0) {
                return warningColor;
            }
        }

        return normalColor;
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Relatório de ' + (delivered ? 'Devoluções' : 'Entregas'),
        pageStyle: `
      @page {
        size: A4;
        margin: 20mm !important;
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact;
        }
        div, table, thead, tbody, tfoot, tr, th, td {
          overflow: visible !important;
        }
        table {
          width: 100%;
          border-collapse: collapse;
        }
        th, td {
          border: 1px solid black;
          padding: 5px;
        }
        tbody {
          display: table-row-group;
        }
        .print-button {
          display: none;
        }
      }
    `,
    });

    function handleCustomerViewClick(id) {
        setShowCustomerViewer(true);
        setCustomerViewerId(id);

    }

    const closeCustomerViewer = () => {
        console.log("closeCustomerViewer called");
        setShowCustomerViewer(false);
        setCustomerViewerId(undefined);
    }

    return (
        <div ref={componentRef}>
            {showCustomerViewer && (
                <CustomerViewer id={customerViewerId} onClose={closeCustomerViewer}/>
            )}
            {showProductSelect && (
                <ProductSelect onItemClick={handleProductItemClick} onClose={closeProductSelect}/>)}
            {showTimePeriodPicker && (
                <TimePeriodPicker
                    startTime={startTimePeriodChanging}
                    endTime={endTimePeriodChanging}
                    onConfirm={changeTimePeriod}
                    onClose={() => setShowTimePeriodPicker(false)}
                />)}
            <div className="header-label">{delivered ? "Controle de Devoluções" : "Controle de Entregas"}</div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>

                <div style={{display: "flex"}}>
                    <div style={{marginTop: '5px', alignItems: 'center'}}>
                        <div style={{width: '180px'}}>Produto:</div>
                        <div style={{marginTop: '5px', display: 'flex', alignItems: 'center'}}>
                            <SearchButton onClick={handleOnClickProduct}/>
                            <input type="text" disabled={true} value={product ? product.name : ''}
                                   style={{flexGrow: 1}}/>
                            {product &&
                                <button className="clean-button" onClick={() => setProduct(undefined)}>Limpar</button>}
                        </div>
                    </div>

                    <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                        {delivered ?
                            "Data Devolução:" : "Data Entrega:"}
                        <div style={{marginTop: '5px', display: 'flex', alignItems: 'center'}}>

                            <DatePicker
                                className="datepicker"
                                selected={startDate}
                                onChange={handleStartDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />
                            até
                            <DatePicker
                                className="datepicker"
                                selected={endDate}
                                onChange={handleEndDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />
                            <select value={deliveryStatus}
                                    onChange={(e) => setDeliveryStatus(parseInt(e.target.value))}>
                                {!delivered && <option key={0} value={0}>A Entregar</option>}
                                <option key={1} value={1}>{!delivered ? 'Entregue' : 'A Devolver'}</option>
                                {delivered && <option key={2} value={2}>Devolvido</option>}
                            </select>
                            <button onClick={() => makeQuery(startDate, endDate, deliveryStatus)}>Consultar</button>
                        </div>
                    </div>
                </div>
            </div>
            <table style={{width: '900px', height: '445px'}}>
                <thead>
                <tr>
                    <th>
                        Código
                    </th>
                    <th>
                        Cliente
                    </th>
                    <th/>
                    <th/>
                    <th>
                        Endereço
                    </th>
                    <th/>
                    <th>
                        Produto
                    </th>
                    <th>
                        {delivered ?
                            "Devolução" : "Entrega"}
                    </th>
                    <th/>
                    <th/>
                </tr>
                </thead>
                <tbody style={{overflowY: 'auto'}}>
                {products.map((item, index) => <tr key={`${item.rental.id}-${index}`}
                                                   style={{backgroundColor: getBackgroundColor(item)}}>
                    <td>
                        <div key={index}>{item.rental.code}</div>
                    </td>

                    <td>
                        <div key={index}>{item.rental.customer.name}</div>
                    </td>
                    <td>
                        <ViewCustomerButton
                            handleCustomerViewClick={() => handleCustomerViewClick(item.rental.customer.id)}/>
                    </td>
                    <td>
                        <WhatsAppButton phoneNumber={item.rental.customer.phone}/>
                    </td>
                    <td>
                        <div
                            key={index}>{item.rental.address +
                            (item.rental.addressNumber !== null && item.rental.addressNumber > 0 ?
                                (', ' + item.rental.addressNumber) : '') +

                            (item.rental.addressComplement !== null && item.rental.addressComplement !== undefined && item.rental.addressComplement !== '' ? ' - ' + item.rental.addressComplement : '') +

                            ' - ' + item.rental.neighborhood}</div>
                    </td>
                    <td>
                        <GoogleMapsButton address={item.rental.address + ', ' + item.rental.addressNumber}
                                          city={item.rental.city + ' - ' + item.rental.state} cep={item.rental.cep}/>
                    </td>

                    <td>
                        {item.rentalItems.map((ri, index) =>
                            <React.Fragment key={index}>
                                <b>Item {index + 1}:</b> {ri.product.name} -
                                Qtd: {ri.quantity}
                                {index <= item.rentalItems.length - 2 && <br/>}
                            </React.Fragment>)
                        }
                    </td>
                    <td>
                        <div
                            key={index} style={{textAlign: 'center', justifyContent: "center"}}>
                            {delivered ? formatDateToString(new Date(item.date)) : formatDateToString(new Date(item.date))}
                            <br/>
                            <div style={{display: 'flex', fontSize: '10px', padding: '0, 2px'}}>
                                {item.startTime ? formatTime(item.startTime) + '-' + formatTime(item.endTime) : ''}
                                <a href="#" onClick={() => {
                                    setStartTimePeriodChanging(item.startTime ? formatTime(item.startTime) : '');
                                    setEndTimePeriodChanging(item.endTime ? formatTime(item.endTime) : '');
                                    setIndexItemChangingTime(index);
                                    setShowTimePeriodPicker(true);
                                }}>
                                    <img src={timeImg} alt="Alterar Período"/>
                                </a>
                            </div>
                        </div>
                    </td>
                    <td>
                        <button className="finish-button" disabled={delivered && item.rentalItems.find((ri) => !ri.delivered)} onClick={async () => {
                            await onFinishClick(item.rental.id, item.date);
                        }}> {delivered && item.rentalItems.find((ri) => ri.deliveryStatus === 0 || ri.deliveryStatus === 1) ? "Devolver" : !delivered && item.rentalItems.find((ri) => ri.deliveryStatus === 0) ? "Entregar" : "Estornar"}
                        </button>
                    </td>

                    <td>
                        {
                            delivered ?
                                <button disabled={delivered && item.rentalItems.find((ri) => !ri.delivered)} onClick={async () => {
                                    const rentalDate = new Date(item.date);
                                    rentalDate.setDate(rentalDate.getDate());
                                    await onRequestExtendRent(
                                        item.rental.id,
                                        item.rentalItems.map((ri, index) => ri.product.id),
                                        adjustDateToBrowserTimeZone(new Date(item.date)));
                                }}>Prorrogar
                                </button>
                                :
                                <button
                                    onClick={async () => await downloadDeliveryStatementByDate(item.rental.id, item.rental.code, item.date)}>Termo</button>
                        }
                    </td>

                </tr>)}
                </tbody>
                {products.length === 0 && (
                    <tfoot>
                    <tr>
                        <td colSpan="100%">Nenhum item encontrado.</td>
                    </tr>
                    </tfoot>)}
            </table>


            <ActionButtonsManagerView>
                <button className="print-button" onClick={handlePrint}>Imprimir</button>
            </ActionButtonsManagerView>


        </div>
    );
};

export default ProductRentedListToDeliverReturn;
