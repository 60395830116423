import React, {useState, useEffect} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import {
    adjustDateToBrowserTimeZone, calculateDaysDifference,
    formatDateString,
    formatDateToAmericanFormat,
    getOnlyDate
} from "../../utils/utils";
import Config from "../../core/config";
import DatePicker from "react-datepicker";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import Cookies from "js-cookie";

const apiUrl = Config.apiUrl;
const CustomerStatistics = () => {
    let [customerStatistics, setCustomerStatistics] = useState([]);
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [, setLoading] = useRecoilState(loading);

    async function makeQuery(query) {
        try {
            setLoading(true);
            const newStartDate = startDate ? startDate : new Date(1900, 0, 1);
            const newEndDate = endDate ? endDate : new Date(2100, 0, 1);
            const url = `${apiUrl}/api/customers/customer-statistics?startDate=${formatDateToAmericanFormat(newStartDate)}&endDate=${formatDateToAmericanFormat(newEndDate)}`;
            const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
            setCustomerStatistics(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        makeQuery('');
    }, []);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleQuery = async () => {
        await makeQuery();
    };

    function copyToClipboard() {
        let clipboardText = `Código\tNome\te-mail\tTelefone\tIdade\tNúmero Locações\tValor Locações\n`;
        clipboardText += customerStatistics.map(customer =>
            `${customer.code}\t${customer.customerName}\t${customer.customerEmail}\t${customer.customerPhone}\t${customer.customerAge}\t${customer.numberOfRentals}\t${customer.totalRentalValue.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })}`).join('\n');

        navigator.clipboard.writeText(clipboardText)
            .then(() => {
                alert('Estatística de clientes copiada para a área de transferência.');
            })
            .catch(err => {
                console.error('Erro ao copiar para a área de transferência: ', err);
            });
    }

    return (
        <>
            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%'}}>
                <div>
                    <div className="header-label">Estatísticas de Clientes</div>
                </div>
                <button onClick={copyToClipboard}>Copiar</button>
            </div>
            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%'}}>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{marginTop: '5px', alignItems: 'center'}}>
                        Período:
                        <div style={{marginTop: '5px', display: 'flex', alignItems: 'center'}}>

                            <DatePicker
                                className="datepicker"
                                selected={startDate}
                                onChange={handleStartDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />
                            até
                            <DatePicker
                                className="datepicker"
                                selected={endDate}
                                onChange={handleEndDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />
                            <button onClick={handleQuery}>Consultar</button>
                        </div>
                    </div>
                </div>
            </div>
            <table style={{width: '850px', height: '380px'}}>
                <colgroup>
                    <col style={{width: '50px'}}/>
                    <col style={{width: '200px'}}/>
                    <col style={{width: '90px'}}/>
                    <col style={{width: '150px'}}/>
                    <col style={{width: '80px'}}/>
                    <col style={{width: '80px'}}/>
                    <col style={{width: '100px'}}/>
                </colgroup>
                <tr>
                    <th>
                        <div style={{textAlign: 'center'}}>Código</div>
                    </th>
                    <th>
                        Nome
                    </th>
                    <th>
                        e-mail
                    </th>
                    <th>
                        Telefone
                    </th>
                    <th>
                        <div style={{textAlign: 'center'}}>Idade</div>
                    </th>
                    <th>
                        <div style={{textAlign: 'center'}}>Número locações</div>
                    </th>
                    <th>
                        <div style={{textAlign: 'right'}}>Valor Locações</div>

                    </th>
                </tr>
                <tbody style={{overflowY: 'auto'}}>
                {customerStatistics.map((customerStatistics, index) => (
                    <tr>
                        <td>
                            <div style={{textAlign: 'center'}} key={index}>{customerStatistics.code}</div>
                        </td>
                        <td>
                            <div key={index}>{customerStatistics.customerName}</div>
                        </td>
                        <td>
                            <div key={index}>{customerStatistics.customerEmail}</div>
                        </td>
                        <td>
                            <div key={index}>{customerStatistics.customerPhone}</div>
                        </td>
                        <td>
                            <div key={index} style={{textAlign: 'center'}}>{customerStatistics.customerAge}</div>
                        </td>
                        <td>
                            <div key={index} style={{textAlign: 'center'}}>{customerStatistics.numberOfRentals}</div>
                        </td>
                        <td>
                            <div key={index}
                                 style={{textAlign: 'right'}}>{customerStatistics.totalRentalValue.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</div>
                        </td>
                    </tr>
                ))}
                </tbody>
                {customerStatistics.length === 0 && (<tfoot>Nenhum item encontrado.</tfoot>)}
            </table>
        </>
    );
};

export default CustomerStatistics;
